// Bootstrap 5 mobile search bar

document.addEventListener('DOMContentLoaded', function () {
  const bs5MobileMenu = document.getElementById('bs5-mobile-menu');

  if (bs5MobileMenu) {
    const searchInput = bs5MobileMenu.querySelector('#mobile-menu-search-input');
    const searchButton = bs5MobileMenu.querySelector('#mobile-menu-search-button');
    const clearSearch = bs5MobileMenu.querySelector('#mobile-menu-clear-search');

    // B5 mobile: Disable the search and hide the clear button until the user types something
    if (searchInput) {
      searchInput.addEventListener('input', () => {
        if (searchInput.value.trim().length > 0) {
          searchButton?.removeAttribute('disabled');
          searchButton?.removeAttribute('aria-disabled');
          searchButton?.classList.remove('disabled');
          clearSearch?.classList.remove('d-none');
        } else {
          searchButton?.setAttribute('disabled', 'disabled');
          searchButton?.setAttribute('aria-disabled', 'true');
          searchButton?.classList.add('disabled');
          clearSearch?.classList.add('d-none');
        }
      });
    }

    // B5 mobile: Search for the input value when pressing enter
    if (searchInput) {
      searchInput.addEventListener('keyup', function(event) {
        const trimmedValue = searchInput.value.trim();
        if (event.key === 'Enter' && trimmedValue.length > 0) {
          window.location.href = `/search?q=${encodeURIComponent(trimmedValue)}`;
        }
      });
    }

    // B5 mobile: Search for the input value when clicking the search button
    if (searchButton) {
      searchButton.addEventListener('click', function(event) {
        const trimmedValue = searchInput.value.trim();
        event.preventDefault();
        window.location.href = `/search?q=${encodeURIComponent(trimmedValue)}`;
      });
    }

    // B5 mobile: Clear the search input, hide the clear button, disable the search button and focus
    // on the input
    if (clearSearch) {
      clearSearch.addEventListener('click', function(event) {
        event.preventDefault();
        searchInput.value = '';
        clearSearch?.classList.add('d-none');
        searchButton?.setAttribute('disabled', 'disabled');
        searchButton?.setAttribute('aria-disabled', 'true');
        searchButton?.classList.add('disabled');
        searchInput.focus();
      });
    }
  }
});
